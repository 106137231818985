export const categoryMapping = {
  All: "all",
  Bites: "bites",
  Wings: "wings",
  Salads: "salads",
  "Fajitas and Sizzles": "fajitas_and_sizzles",
  Pastas: "pastas",
  Burgers: "burgers",
  Sandwiches: "sandwiches",
  Desserts: "desserts",
  Sides: "sides",
};
